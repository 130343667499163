.nav {
  display: flex;  
  flex-direction: column;

  align-items: flex-start;
  padding: 0.5rem;
  background-color: #000000;
  position: sticky;
  top: 0;
  z-index: 2000;
  justify-content: space-between;
}

a {
  color: #fff;
  text-decoration: none;
  font-weight: 900;
  font-size: 1.8rem
}

.button {
  padding: 0.6rem;
  margin: 0 0.5rem 0 0.5rem;
  cursor: pointer;
  outline: none;
  color: rgb(2, 30, 86);
  font-size: 1rem;
  font-weight: 600;
  border-radius: 6px;
  min-width: 120px;
}

.button:hover {
  background-color: rgb(244, 169, 50);
  color: #fff;
}
.header-link {
  background-color: #002945;
  color: #77c7a7;
  border: none;
  outline: none;
}
.searchField {
  width: 100%;
  background-color: black;
  border-radius: 20px;
  color: white;
  font-size: 20px;
  padding: 15px;
  border: none;
  outline: none;
}
.searchFieldFocus {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  outline: none;
  /* border: none; */
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }
  100% {
    opacity: 1;
    transform: translateY(-0);
  }
}
.nav-menu {
  display: flex;  
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  background-color: #000000;
  position: sticky;
  top: 0;
  z-index: 2000;
  justify-content: space-around;
}
.hamberger-icon{
  border: none;
  background: none;
  color: lightgreen;
  font-size: 1.4rem;
  font-weight: bold;
}
.menu{
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: #006141;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

}

.nav-btn{
  background-color: rgba(144, 238, 144, 0.33) !important;
border-radius: 15px !important;
}

.nav-btn:focus {
  box-shadow: #569a50 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.nav-btn:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #ced3cc 0 -3px 0 inset;
  transform: translateY(-2px);
}

.nav-btn:active {
  box-shadow: #114321 0 3px 7px inset;
  transform: translateY(2px);
}
@media (max-width: 768px) {

  .nav-menu {
    display: flex;  
    flex-direction: column;
   
  }
  .res-nav{
    display: block !important;
  }
  .res-appbar{
    position: absolute !important;
  }
  .res-pecubtn{
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
@media (max-width: 576px) {
  .nav{
    display: flex;
    flex-direction: row;
  }
  .res-margin{
    margin-top: 5px !important;
  }
}

