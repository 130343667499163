/* -------------- Header-Tab ---------------- */

.headerTab{
    color: rgb(234, 236, 239) !important;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    text-transform: capitalize !important;
    padding:12px 27px !important;
    font-size: 14px;
    cursor: pointer;
    
   
}
.headerTab.Mui-selected{
    color: rgb(242, 242, 242) !important;
    text-transform: capitalize ;
    background-color: black !important;
}
.headerTab:hover{
    background-color: #1a1b1f;
}
.optionSetting{
    padding: 12px 32px;
    text-transform: capitalize;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    background-color: rgb(33, 36, 43);
    border: none;
    display: flex;
    align-items: center;
}
.optionSetting:hover{
    background-color: #2d2f37;;
}
.optionSetting > svg {
    margin-left: 10px;
}
.option-btn {
    padding: 0 !important;
}
.option-btn > div{
    margin: 0 !important;
    justify-content: end;
}
.option-btn{
    min-height: 0 !important;
}
.checkBox-button >span> svg{
    font-size: 20px ;
}
.checkBox-button >span{
    font-size: 14px ;
}
.minMax-dropdown > div{
    padding:6px 10px;
    border: 1px solid #91a0b1;
}
.minMax-dropdown > svg{
    color:#fff;
}
.strike-Range {
    margin-right: 15px;
}
.tiled-Sign{
    margin:0 10px 0 10px;
}
.drop-Down{
    font-size: 13px;
}
.css-6hp17o-MuiList-root-MuiMenu-list {
    background-color: #2d2f37 !important;
    color: white !important;
}
@media (max-width: 768px){
    .headerTab_Accordion,.Header_Button{
        display: none !important;
    }
}

/* -------------- Main-Tab ---------------- */

.css-1aquho2-MuiTabs-indicator{
    background-color: rgb(242, 242, 242) !important;
} 
.main-Tab{
    border:1px solid rgba(255, 255, 255, 0.2) !important;
    color: white !important;
    text-transform: capitalize !important;
}
.main-Tab.Mui-selected{
    background-color: rgb(33, 36, 43) !important;
    text-transform: capitalize !important;
    color: white !important;
}
.main-Tab:hover{
    background-color: #1a1b1f;
}
.signIn, .getStarted{
    color:white ;
    text-transform: capitalize;
    background-color: #000;
    padding: 10px 16px;
    border-radius: 4px;
    font-weight: 750;
    cursor: pointer;
}
.signIn:hover{
    border: 1px solid #91a0b1;
}
.getStarted{
    background-color:#91a0b1 ;
}
.getStarted:hover{
    background-color: #7b8795;
}
.Trade-Usd{
    padding:14px 16px !important;
}
.usd-Set{
    font-size: 12px !important;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    white-space: nowrap;
    width: 100%;
}
.box-Width{
    width:400px;
    background-color: #000 !important;
    color: #fff !important;
    border:1px solid rgba(255, 255, 255, 0.2) !important;
    display: grid;
    grid-template-columns: 1fr calc(15px + 1rem);
}
.Popover-icon > svg{
    font-size: 19px;
}
.availablePosition_Set{
    font-size: 14px !important;
    display: grid;
    width: 100%;
    text-align: end;
}
.password_Icon > svg{
    margin-left: 5px;
    font-size: 16px;
}
.callsPuts_button{
    width: 50%;
    color:#91a0b1 !important;
    border: 1px solid #91a0b1 !important;
}
.callsPuts_button.Mui-selected{
    color: white !important;
}
@media (max-width: 768px){
    .box-Width, .sign-Started{
        display: none !important;
    }
    .mainTab_Accordion{
        display: none;
    }
    .main-Tab{
        width: 50% !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #91a0b1 !important;
    }
    .mainTab_Box{
        width: 100%;
    }
    .mainTab_BtcBox{
        display: block !important;
    }
}

/* -------------- Left-Panel ---------------- */

.paperStyle{
    font-style: italic;
    padding: 10px 0px;
    font-weight: 500;
}
.contract-Price{
    padding:15px 0px;
    background-Color:rgb(33, 36, 43) !important;
    color: #91a0b1 !important;
}
.contracts {
    font-size: 13px;
    display: flex;
    gap: 4px;
}
.infoIcon > svg{
    font-size: 17px;
}
.size_Button{
    border: 1px solid #91a0b1 !important;
    box-sizing: content-box !important;
    justify-content: start !important;
    padding: 3px 14px !important;
    font-size: 14px !important;
    text-transform: capitalize !important;
    color: #91a0b1 !important;
}
.buy-Sell{
    color:#91a0b1;
    background-color: #1a1b1f;
    width: 160px;
    border: none;
    border-radius: 5px;
    padding: 13px;
}
.trades-Button{
    background-color: #2d2f37;
    border: none;
    padding: 10px 10px;
    border-radius: 2px;
    width: 109px;
    color:#91a0b1;
}
@media (max-width: 768px){
    .Left_panel {
        display: none !important;
    }
}

/* -------------- Table-Tab ---------------- */

.tbody-table tr td , .tbody-table tr th {
    border-bottom: 1px solid #1e2128;
    padding: 16px !important;
    color: #91a0b1;
}
.table_wrapper  {
    width : 100%;
    height: 500px;
    overflow-y: auto;
}
.table_wrapper2 {
    width : 100%;
    height: 430px;
    overflow-y: auto;
    margin-top: 0px;
}
.table_wrapper::-webkit-scrollbar , .table_wrapper2::-webkit-scrollbar{
    width: 10px;
    background-color: #000;
}
.table_wrapper::-webkit-scrollbar-thumb ,.table_wrapper2::-webkit-scrollbar-thumb{
    background-color: #91a0b1;
    width: 10px;
    border-radius: 5px;
}
.tableRows-color > th{
    color: #c3c5cb !important;
    background-color: #1a1b1f ;
}
.custom-color{
    background-color: #000;
    color: #c3c5cb !important;
    text-align: end;
}
.strike-Row{
    background-color: #2d2f37;
    text-align: center !important;
}
@media (max-width: 768px){
    .table_wrapper::-webkit-scrollbar{
        width: 4px;
    }
}

/* -------------- TradeTable-Tab ---------------- */

.head-head > th{
    color: #c3c5cb ;
    background-color:#21242b;
}
@media (max-width: 768px){
    .Bottom_Table{
        display: none !important;
    }
}




.Full_Page {
    display: flex;
    flex: 0 1 100%;
    overflow: auto;
}
.Page_Grid{
    display: grid;
    min-height: 958px;
    grid-template-columns: 600px minmax(400px, 1fr);
    grid-template-rows: min-content 1fr;
    gap: 4px;
    padding-left: 2px;
    overflow: auto hidden;
    width: 100%;
    height: 100%;
}

@media (max-width: 768px){
    .Page_Grid{
        display:block;
        min-height: auto;
    }
}
.Grid_Column{
    grid-column: 1 / 4;
}
.Main_Tab{
    font-size: 12px;
    line-height: 1.4;
    margin-bottom: 1px;
}
.Main_sub{
    display: flex;
    flex-wrap: nowrap;
}
.Header_Tab{
    display: flex;
}
.Header_TabPanel{
    display: grid;
    grid-template-columns: repeat(4, max-content);
    gap: 1px;
}
.Header_Button{
    display: flex;
    -webkit-box-pack: end;
    justify-content: flex-end;
    width: 100%;
    background-color: rgb(33, 36, 43);
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 13px;
    font-weight: 700;
}
.Right_panel{
    grid-template-columns: 1fr;
    grid-template-rows: minmax(450px, 7fr) minmax(250px, 4fr);
    row-gap: 2px;
    height: auto;
    font-size: 13px;
    overflow: hidden; 
}




.active{
    flex: 1 1 0%;
    text-align: center;
    cursor: pointer;
    line-height: 150%;
    font-weight: 600;
    font-size: 1rem;
    border-radius: 6px;
    margin: 2px;
    padding: 0.5rem;
    background-color: rgb(0, 153, 76);
    color: rgb(255, 255, 255);
    transition: background-color 300ms ease 0s, color 300ms ease 0s;
}


.tabs{
    border:2px solid  #000;
    background-color: #000;
    color: white;
    margin:10px ;
    border-radius: 8px;
    font-size: 12px;
}