.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 1rem;
  padding: 0.7rem, 0;
  color: #fff;
  text-align: center;
  background-color: rgb(0, 12, 0);
  box-shadow: 0px 0px 1px 0px rgba(255, 255, 255, 1);
  height: 250px;
  
}

.lightGreen{
  color: rgb(56, 218, 45);
  font-size: 1rem !important;
  font-weight: 400;
  
cursor: pointer;
}
.bold-600{
  font-weight: 600;

}
.green{
  color: green;
 
  font-size: 0.85rem;
}

.lightGreen:hover{
color: rgb(1, 105, 1);
}

p {
  margin: 0;
  padding: 0;
}
