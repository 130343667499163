 .news-c1, .news-c3{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: black;
    flex: .25;
}


.news-c2{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: black;
    flex: .4;
}

.news-c3{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: black;
    max-width: 300px;
   
}

.news-r1{
    flex: 1;
    background-color: #cef6ce;
    margin: 1rem;
    min-width: 250px;
    max-height: 300px;
    padding:5px;
    border: 1px solid rgb(198, 196, 196);
    border-radius: 16px;
 }

 .news-r2{
    flex: 1;
    background-color: #cef6ce;
    margin: 1rem;
    min-width: 200px;
    max-height: 300px;
    padding: 1rem;
    border: 1px solid rgb(198, 196, 196);
    border-radius: 16px;
     
 }

.news-r3{
    flex: 1;
    border-left: 5px solid rgb(0, 128, 0);
    margin: 1rem;
    min-width: 250px;
    max-height: 300px;
    padding: 1rem;
    border: 1px solid rgb(198, 196, 196);
    border-radius: 16px;
    background-color: #cef6ce;
     
 }

 .readMore{
    color: #075307;
    font-weight: 750;
    font-size: 16px;
 }
 
 .video_News{
    flex: 1;
    margin: 1rem;
    padding: 1rem;
    border-radius: 16px;
    border: none;
    width: 700px;
    height: 460px;
 }
 .news_wrapper1 {
    width : 100%;
    height: 1385px;
    overflow-y: auto;
    
}
.news_wrapper2 {
    width : 100%;
    height: 860px;
    overflow-y: auto;
}
.news_wrapper1::-webkit-scrollbar , .news_wrapper2::-webkit-scrollbar{
    width: 10px;
    background-color: rgba(1, 64, 43, 0.765);
}
.news_wrapper1::-webkit-scrollbar-thumb ,.news_wrapper2::-webkit-scrollbar-thumb{
    background-color: #000;
    width: 10px;
    border-radius: 5px;
}
.news-r1 > span > p,
.news-r2 > span > p,
.news-r3 > span > p{
    margin:5px 0 ;
}
.news-r1 > p,
.news-r2 > p,
.news-r3 > p{
    margin:0 0 20px 0 ;
}
.news-r1 > h4,
.news-r2 > h4,
.news-r3 > h4 {
    margin: 5px 0 20px 0;
}
 @media (max-width: 768px){
    .news_section {
        display: block !important;
    }
    .video_News{
        width: 360px;
        height:250px;
    }
}